@import '../../styles/variables'

.header
  display grid
  grid-auto-flow column
  grid-template-columns auto max-content min-content
  grid-column-gap $space-m
  margin $space-stack-l
  align-items center

.header__actions
  display grid
  grid-auto-flow column
  grid-column-gap $space-s
  align-self start

.header__status
  font-bold()
  color $grey
  align-self start
  padding $space-xs 0

.header__status--error
  color $red-darker

.header__status--success
  color $green-darker

.header--sub
  margin $space-stack-m
