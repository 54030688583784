@import '../../styles/variables'

.button
  display grid
  grid-auto-flow column
  grid-column-gap $space-xs
  font-bold()
  letter-spacing 0.8px
  border-radius 5px
  border none
  font-size 12px
  padding $space-s $space-m
  justify-self start
  cursor pointer
  text-transform uppercase
  background $grey-lightest
  color $grey-darker
  &:hover
    background $grey-lighter
  &:active
    background $grey-light
  &:focus, &:active
    outline none
  &:disabled
    opacity 0.6
    cursor not-allowed

.button__icon
  display inline-grid
  place-content center

.button__text
  white-space nowrap

.button--small
  padding $space-xs $space-s
  font-size 11px

.button--tiny
  padding $space-xxs $space-s
  font-size 8px

.button--text
  border none
  background none
  padding $space-s
  border-radius 5px
  cursor pointer
  color $grey-dark
  &:hover
    background $white-ter
    color $grey-darker
  &:active
    background $grey-lightest

.button--quiet
  color $grey
  &:hover
    background $white-ter
    color $grey-dark
  &:active
    background $grey-lightest

.button--grey
  background $white-ter
  color $grey-dark
  &:hover
    background $grey-lightest
  &:active
    color $grey-darker
    background $grey-lighter

.button--red
  background $red-lightest
  color $red-dark
  &.button--text
    background none
    &:hover
      color $red-darker
      background $red-lightest
    &:active
      background $red-lighter
  &:hover
    background $red-lighter
  &:active
    color $red-darker
    background $red-light

.button--green
  background $green
  color white
  &.button--text
    background none
    color $green-darker
    &:hover
      background $green-lightest
    &:active
      background $green-lighter
  &:hover
    background $green-dark
    border-color $green-darker
  &:active
    background $green-darker
    border-color $green-darkest

.button--red .button__icon
  fill $red

.button--disabled-text:disabled
  background none

.button--emoji
  font-emoji()
