$space-inset-default = 16px 16px 0 16px
$space-inset-xs = 4px 4px 0 4px
$space-inset-s = 8px 8px 0 8px
$space-inset-m = 16px 16px 0 16px
$space-inset-l = 32px 32px 0 32px
$space-inset-xl = 64px 64px 0 64px
$space-stack-default = 0 0 16px 0
$space-stack-one = 0 0 1px 0
$space-stack-xs = 0 0 4px 0
$space-stack-s = 0 0 8px 0
$space-stack-m = 0 0 16px 0
$space-stack-l = 0 0 32px 0
$space-stack-xl = 0 0 64px 0
$space-default = 16px
$space-xxs = 2px
$space-xs = 4px
$space-s = 8px
$space-m = 16px
$space-l = 32px
$space-xl = 64px

$max-width = 1150px
$text-max-width = 500px

$grey-darker = hsl(0, 0%, 10%)
$grey-dark = hsl(0, 0%, 29%)
$grey = hsl(0, 0%, 50%)
$grey-light = hsl(0, 0%, 71%)
$grey-lighter = hsl(0, 0%, 86%)
$grey-lightest = hsl(0, 0%, 91%)
$white-ser = hsl(0, 0%, 93%)
$white-ter = hsl(0, 0%, 96%)
$white-bis = hsl(0, 0%, 99%)

$red-darkest = hsl(358, 72%, 20%)
$red-darker = hsl(358, 72%, 40%)
$red-dark = hsl(358, 72%, 46%)
$red = hsl(358, 72%, 55%)
$red-light = hsl(358, 72%, 71%)
$red-lighter = hsl(358, 72%, 86%)
$red-lightest = hsl(358, 72%, 91%)
$red-ter = hsl(358, 72%, 96%)
$red-bis = hsl(358, 72%, 98%)

$green-darkest = hsl(154, 71%, 20%)
$green-darker = hsl(154, 71%, 40%)
$green-dark = hsl(154, 71%, 46%)
$green = hsl(154, 71%, 50%)
$green-light = hsl(154, 71%, 71%)
$green-lighter = hsl(154, 71%, 86%)
$green-lightest = hsl(154, 71%, 91%)
$green-ter = hsl(154, 71%, 96%)
$green-bis = hsl(154, 71%, 98%)

$orange = hsl(39, 88%, 48%)

font-regular()
  font-family 'HM Sans Regular', sans-serif

font-bold()
  font-family 'HM Sans Bold', sans-serif
  font-weight bold
  -webkit-font-smoothing antialiased

font-light()
  font-family 'HM Sans Light', sans-serif
  font-weight lighter

font-emoji()
  font-family 'EmojiSymbols', sans-serif
