@import '../../styles/variables'

.navigation__item
  position relative
  color rgba(255, 255, 255, 0.9)
  font-bold()
  display grid
  grid-auto-flow column
  grid-column-gap $space-xs
  align-items center
  cursor pointer
  text-decoration none

.navigation__item--active:after
  position absolute
  content ''
  left 0
  right 0
  bottom 0
  border-bottom 2px solid rgba(255, 255, 255, 0.6)

