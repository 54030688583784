@import '../../styles/variables'

.navigation
  width $max-width
  display grid
  justify-content space-between
  grid-template-columns auto auto
  padding $space-s 0

.navigation__brand
  text-transform uppercase
  font-weight 600
  letter-spacing 1px
  color white
  text-decoration none
  display grid
  align-items center
  grid-template-columns auto auto
  grid-column-gap $space-s

.navigation__main
  display grid
  grid-auto-flow column
  grid-column-gap $space-m

.navigation__icon
  font-size 20px
  color hsl(358, 100%, 80%)
  fill hsl(358, 100%, 80%)
