@import '../../styles/variables'

.modal
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background rgba(0, 0, 0, 0.4)
  z-index 100
  display none

.modal__inner
  background white
  width 700px
  top $space-xl
  position fixed
  left 50%
  transform translateX(-50%)
  overflow-y auto
  max-height "calc(100vh - %s)" % $space-xl

.modal--fixed .modal__inner
  bottom $space-xl

.modal--open
  display block

.modal
  .react-tabs__tab-list
    padding $space-inset-m
    margin $space-stack-m
