@import '../../styles/variables'

.form
  display grid
  grid-auto-flow row

.form__header
  padding $space-inset-m

.form__content
  display grid
  grid-row-gap $space-m

.form__content--disabled
  pointer-events none
  opacity 0.7
