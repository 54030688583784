@import 'variables'

body
  font-regular()
  font-size 16px
  color $grey-dark
  background hsl(0, 0%, 97%)
  overflow-y scroll
  padding-bottom $space-l

.app
  display grid
  grid-row-gap $space-l

.container
  display grid
  justify-content center
  grid-template-columns $max-width

.container--red-darker
  background $red-darker

.container--red-dark
  background $red-dark

