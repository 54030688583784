@import '../../styles/variables'

.title
  display grid
  grid-column-gap $space-m
  grid-auto-flow column
  grid-auto-columns max-content
  align-items center

.title__title
  font-size 24px

.title--sub
  .title__title
    font-size 18px

.title__badge
  background $grey-dark
  padding $space-xxs $space-s
  border-radius 2px
  font-size 11px
  color $white-bis
  text-transform uppercase
  letter-spacing 1px
